import React from 'react'
import {graphql, StaticQuery} from 'gatsby'
// import styled from '@emotion/styled'

import Layout from '../containers/layout'
import SEO from '../components/seo'
// import Container from '../components/container'
import Page from '../components/contact-page'

// import styles from '../components/home.module.css'

export default class Index extends React.Component {
  render () {
    return (
      <StaticQuery
        query={graphql`
             query sanityContact {
               sanityContact {
                 title
                 _rawBody
                 mainImage {
                   asset {
                     fluid {
                       ...GatsbySanityImageFluid
                     }
                   }
                 }
                 formSettings {
                   thankyou
                   emailSubject
                   emailTo
                 }
                 seoSettings {
                   title
                   description
                 }
               }
             }
          `}
        render={data => (
          <>
            {data.sanityContact.seoSettings && data.sanityContact.seoSettings.title && data.sanityContact.seoSettings.description && (<SEO title={data.sanityContact.seoSettings.title} description={data.sanityContact.seoSettings.description} />)}
            <Layout Layout mainImage={data.sanityContact.mainImage}>
              <Page
                title={data.sanityContact.title}
                _rawBody={data.sanityContact._rawBody}
                mainImage={data.sanityContact.mainImage}
                thankYou={data.sanityContact.formSettings.thankyou}
                emailSubject={data.sanityContact.formSettings.emailSubject}
                emailTo={data.sanityContact.formSettings.emailTo}
              />
            </Layout>
          </>
        )}
      />
    )
  }
}
